var PROJECTS = (function($) {	

	function init() {

		var $filter = $('.filter-item');
		var $projects = $('.projects');

		$projects.isotope({
			// options
			itemSelector: '.masonry-item',
			percentPosition: false,
			stagger: 30,
			masonry: {
				columnWidth: '.grid-sizer',
				gutter: 32
			}
		});

		$projects.imagesLoaded(function(){	
			$projects.isotope();	
		});	

		if($filter.length) {

			$filter.on('click', function(e) {
				e.preventDefault();
				$('.filter-item.active').removeClass('active');
				$(this).addClass('active');
				var f = $(this).data('f');
				$projects.isotope({filter: f});		
			});
		}
		
	}	


    return {
    	init: init
    };

})(jQuery);