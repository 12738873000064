var TYPED = (function($) {	

	

	function init() {

		var $typed = $(".typed");
		var strings = $typed.data('words');
		stringsArray = strings.split(',');
		
		$(".typed").typed({
			strings: stringsArray,
			// Optionally use an HTML element to grab strings from (must wrap each string in a <p>)
			stringsElement: null,
			// typing speed
			typeSpeed: 40,
			// time before typing starts
			startDelay: 0,
			// backspacing 2speed
			backSpeed: 30,
			// time before backspacing
			backDelay: 800,
			// loop
			loop: true,
			// show cursor
			showCursor: false,
			// attribute to type (null == text)
			attr: null,
			// either html or text
			contentType: 'html'
		});

	}	


    return {
    	init: init
    };

})(jQuery);