var TRANSITION = (function($) {	


	var tlTransition = new TimelineLite();
	var tlPreloader = new TimelineLite();
	var transitionLayer = $('.transition-layer');
	var preloader = $('.preloader');

	function transition() {

		tlTransition
		.to(preloader, 0.70, {ease: Power2.easeOut, autoAlpha: 1})

		.add(function() {
			tlTransition.pause();
			window.scrollTo(0,0);
		})
		.to(preloader, 0.70, {ease: Power2.easeOut, autoAlpha: 0})

	}

	function continueTransition() {
		tlTransition.play();
	}

	function firstLoaded() {
		tlPreloader
		.add(function() {
			REFRESH.init();
		})
		.to(preloader, 0.7, {ease: Power2.easeOut, autoAlpha: 0})


	}


    return {
    	init: transition,
    	continue: continueTransition,
    	firstLoaded: firstLoaded
    };

})(jQuery);


