var first = true;

jQuery(window).load(function() {

	SMOOTHSTATE.init();
	
	setTimeout(function() {
		TRANSITION.firstLoaded();
	}, 300);
	
});