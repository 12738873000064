var POPUP = (function () {
  var overlay, close;

  function closeDialog(e) {
    if (
      !e.target.classList.contains("popup__overlay") &&
      !e.target.classList.contains("popup__close")
    )
      return;

    overlay.classList.remove("is-active");
    sessionStorage.setItem("hasBeenOpened", true);
  }

  function init() {
    overlay = document.querySelector(".popup__overlay");
    close = document.querySelector(".popup__close");

    overlay.addEventListener("click", closeDialog);
    close.addEventListener("click", closeDialog);

    let hasBeenOpened = sessionStorage.getItem("hasBeenOpened");

    if (!hasBeenOpened) {
      setTimeout(function () {
        overlay.classList.add("is-active");
      }, 2000);
    }
  }

  return {
    init: init,
  };
})();
