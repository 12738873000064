

var SLICK = (function($) {	

	function init() {

		var $testimonials = $('.testimonials');

		$testimonials.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 6000,
			cssEase: 'linear',
			arrows: true,
			dots: true,
			fade: true
		});
		
	}	


    return {
    	init: init
    };

})(jQuery);

