var REFRESH = (function ($) {
  function init() {
    if ($(".typed").length) {
      setTimeout(function () {
        TYPED.init();
      }, 1200);
    }

    if ($(".projects").length) {
      PROJECTS.init();
    }

    if ($(".testimonials").length) {
      SLICK.init();
    }

    if ($(".hero-bg").length) {
      HEROLOADER.init();
    }

    if ($(".popup").length) {
      POPUP.init();
    }

    GENERAL.init();
  }

  return {
    init: init,
  };
})(jQuery);
