var HEROLOADER = (function($) {	

	function init() {

		var $hero = $('.hero-bg');

		$hero.imagesLoaded(function(){	
			$hero.addClass('loaded');
		});	
		
	}	


    return {
    	init: init
    };

})(jQuery);