var SMOOTHSTATE = (function($) {	

	var smoothState;
	var $body = $('body');

	var options = {
		blacklist: 'form, .no-smoothState, .menu-item-has-children>a',
		onStart: {
			duration: 800,
			render: function ($container) {

				first = false;
				TRANSITION.init();

			}
		},
		onReady: {
			duration: 0,
			render: function ($container, $newContent) {

				if($body.hasClass('menu-open')) {
					$body.removeClass('menu-open');
				}
				
				$container.html($newContent);
				TRANSITION.continue();

			}
		},
		onAfter: function($container, $newContent) {
			REFRESH.init();
		}
	}

	function init() {

		smoothState = $('#wrapper').smoothState(options).data('smoothState');

	}
	


    return {
    	init: init
    };

})(jQuery);