var GENERAL = (function($) {
  function init() {
    var $body = $("body");
    var $menuButton = $(".menu-button");

    $menuButton.on("click", function(e) {
      $body.toggleClass("menu-open");
    });

    // Google Analytics pageview event
    if (typeof ga !== "undefined" && !first) {
      ga("send", "pageview", location.pathname);
    }
  }

  return {
    init: init
  };
})(jQuery);
